<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-container id="topo">
          <a style="text-decoration: none;color: #70445E;" @click.stop="voltar()">VOLTAR</a>
          <v-row class="mt-5">
            <v-col cols="12" class="text-h6" style="color: #70445E;">EN DEHORS – Edição Julho/Agosto</v-col>
            <v-col cols="12">
              <v-card
                style="color: #70445E;background-color: transparent;"
              >
              <v-expansion-panels multiple accordion v-model="panel">
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – DEMOCRATIZAÇÃO DA ARTE EM ESPAÇOS, CLASSES, ECONOMIAS e CORPOS – passeando entre Joinville e os espaços em branco de P. Auster</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content mt-3">
                    <p class="subtitle-1" style="color: #70445E;"><strong>DEMOCRATIZAÇÃO DA ARTE EM ESPAÇOS, CLASSES, ECONOMIAS e CORPOS – passeando entre Joinville e os espaços em branco de P. Auster</strong></p>
                    <p>&nbsp;Essa coluna é publicada na edição de julho da nossa revista, e todo mês de julho me deixa nostálgica… é o meio do ano e já foi o centro da minha vida de jovem bailarina: Festival de Dança de Joinville! É dele que estou falando, o maior e mais importante do país, acontece nessa época. Em 2022, chega a 39ª edição, todas repletas de grandes nomes, grandes trabalhos, diversas modalidades, diversidade e muita dança.</p>
                    <p>&nbsp;A grande atração de abertura este ano fica por conta do Balé do Teatro Guaíra (BTG). Lembro dos meus olhos de menina bailarina, cheios d’água, vendo “O Segundo Sopro” (1999), conhecido como “balé das águas”, a primeira coreografia no Brasil que tinha chuva no palco, inovação trazida pelo BTG. Dançar é entrega. Deixar molhar é uma fusão do corpo com a natureza que simboliza bem o que atravessa a bailarina no palco. Esse enigma, esse sentir, esse êxtase, sempre me capturou. Estar na chuva é querer molhar… dança transpira e chora naturalidades.</p>
                    <p>&nbsp;Apesar de ser linda a atmosfera artística que atrai o festival, há algo importante a ser lembrado e discutido: o quanto a arte é acessível? Do que vivem os bailarinos e como financiam suas idas a eventos importantes assim?</p>
                    <p>&nbsp;Conversei com a bailarina Helen Ribeiro, graduada com Licenciatura em Dança pela UFMG, e que está indo pela quinta vez a Joinville. Assim me contou: “A minha escola sempre fazia excursão para Joinville e, aos 14 anos, pedi para ir (como presente pelo aniversário de 15 anos). Queria estar com meu grupo e vivenciar todas as experiências possíveis. Nessa época, estava muito apaixonada e envolvida com ballet clássico. Depois disso, fui mais algumas vezes, porém sempre para fazer cursos e assistir danças. Nunca cogitei dançar nos palcos. Achava impossível ser selecionada no meio de tantas pessoas boas”.</p>
                    <p>&nbsp;Em especial desta vez, Helen teve seu vídeo aprovado nas seletivas e se apresentará nos palcos do festival com sua dança de salão. No entanto, ela fala das dificuldades para realização desse sonho: “(…) a dificuldade enquanto artista jovem e autônoma é arrecadar dinheiro que cubra custo básico da viagem, comida, hospedagem e avião. Por ser um festival grande e nas férias, tudo se torna três vezes mais caro e ainda como artista e professora não alcancei um salário que mereço e que permita viver tranquila. Principalmente, após ter ficado um tempo sem poder trabalhar pela pandemia, a classe artística foi muito afetada e ainda estou buscando espaços e oportunidades para fazer o que tanto amo: trabalhar com Dança.”</p>
                    <p>&nbsp;Helen está correndo contra o tempo para arrecadar o valor que possibilite estar presente no festival e, na próxima edição da coluna En Dehors, poderá ser lida sua entrevista completa (com relato sobre a realização desse sonho, conforme esperamos!). Até lá, os passos dessa jornada podem ser acompanhados pelo IG @bailarinahelenribeiro .</p>
                    <p>&nbsp;Nossa entrevistada não é um caso isolado. Lembro da diferença com que convivi (na pele). No mesmo grupo em que eu dançava, havia quem fosse para o festival viajando de avião (saíamos de São Paulo) e emendando uma semana de férias no Beto Carrero World. Eu ia de ônibus, com dinheiro calculado para uma semana de alimentação e – que minha mãe não leia isso (risos) – economizava na comida para trazer uma camiseta do festival para casa. O Centreventos, onde aconteciam as apresentações, era uma tentação para as bailarinas comprarem: sapatilhas, vestimentas e todos os acessórios do mundo da dança nos eram oferecidos o tempo inteiro.</p>
                    <p>&nbsp;Já ouviram alguém dizer que o ballet (e a cultura no geral) é para elite? Pois bem…</p>
                    <p>&nbsp;Por falar em realidade socioeconômica, decentralizar ações culturais é algo de importante que a turma da dança costuma fazer. E é preciso que seja feito sempre e ainda mais. Nos últimos dias 25 e 26 de junho, estive no palco do Encontro da Dança, que reuniu 40 escolas de dança da cidade de Belo Horizonte (MG) em apresentação gratuita no Shopping Boulevard. Democratização da arte.</p>
                    <p>&nbsp;Dancei, desde adolescente, nos chamados palcos livres, montados nas ruas, em praças públicas, centros comerciais, em Joinville, Ribeirão Preto, Campos do Jordão, entre outros. Arte precisa ser ofertada, compartilhada, vivida socialmente. Não se trata de nenhuma aproximação com a política do “pão e circo”, mas sim do circo ser o pão para mais que o corpo: de riso e beleza também se vive e se faz viver.</p>
                    <p>&nbsp;Existe algo na dança que prescinde de palavras. Há uma linguagem de gestos construídos na sintaxe do inconsciente que põe tanto bailarinos quanto expectadores diante do que são os significados de cada dizer do corpo para si.</p>
                    <p>&nbsp;Mencionei, na edição passada, que traria algo de bonito que vi na biografia do poeta Paul Auster: o poeta a quem a dança abalou.</p>
                    <p>&nbsp;O norte-americano teve seus poemas escritos entre 1967 e 1980 e dizia que “poesia é como tirar fotografias, enquanto prosa é como rodar um filme”.</p>
                    <p>&nbsp;Em dezembro de 1978, Auster, por acaso, assistiu a um ensaio de um espetáculo de dança coreografado pela amiga de um amigo. Algo aconteceu… A dança mexeu com o poeta a ponto de movê-lo a escrever em um tempo que pouco vinha produzindo. Ele havia praticamente cessado com sua escrita, conforme havia dito em entrevista concedida a Larry McCaffery e Sinda Gregory, em A arte da fome, no mesmo ano. Sem fotografias…</p>
                    <p>&nbsp;Da inspiração daquele momento, Auster começa a escrever o texto intitulado “Espaços em branco” que diz não ter gênero claro e ter sido uma libertação. Um breve compilado de trechos do trabalho é capaz de nos apontar o tamanho do encantamento que a dança proporciona.</p>
                    <p>&nbsp;Assim ele inicia: “Algo acontece, e a partir do momento em que começa a acontecer, nada poderá voltar a ser como era”. Era dança o que acontecia defronte a seus olhos. E algo de ininterpretável acontecia das retinas para dentro. Irreversível.</p>
                    <p>&nbsp;Ele prossegue: “Algo começa, e já não é mais o começo, mas outra coisa, que nos impele ao coração da coisa que acontece” … o peito do poeta pulsando com a batida do bailado que vê. Sobre o quanto o movimento dançado comunica, prossegue, em sua prosa de libertação: “(…) sons não são menos um gesto do que é uma mão quando se estende no ar para outra mão, e nesse gesto pode-se ler todo o alfabeto do desejo, a necessidade do corpo ser levado para além de si próprio, mesmo quando reside na esfera de seu próprio movimento”.</p>
                    <p>&nbsp;Eis um mistério universal: não há quem saia o mesmo quando se permite dançar, seja no palco, seja na cadeira de expectador. Os corpos ficam a serviço do mundo simbólico, inventando novas formas de expressão: dialeto de desejos!</p>
                    <p>&nbsp;Tal universalidade abrange toda a diversidade e a sessão Pas De Deux dessa edição traz uma conversa para lá de relevante e esclarecedora, ainda sobre a democratização da arte, mas sob outro viés.</p>
                    <p>&nbsp;</p>
                    <video controls width="346" height="432">
                      <source src="https://cdn.discordapp.com/attachments/1004541366904291338/1010202720256409710/video4900448244674331445_1.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                    </video>
                    <p style="text-align: center;"><a href="https://www.instagram.com/p/CeRnMAxAiQ7/" role="link" target="_blank">Assistir no Instagram</a></p>
                    <p style="text-align: center;">Dança menina dança.<br>
                    Tira a roupa de mulher e faz semblante de maré.<br>
                    Valsa a vontade movediça de ter pé em chão de areia:<br>
                    sempre em movimento, sempre de passagem.<br>
                    Escuta o bandolim que faz brisa nos pelos e veste a pele de música.<br>
                    Dança, menina, dança!<br>
                    Sabendo que dançar é risco que se toma com todo o corpo:<br>
                    risca o ar e escreve o gesto.<br>
                    Deixa a alma escapar pelos poros porque dançar não é performance.<br>
                    A estética mais encantadora é a da pulsão que sobressalta em dança e<br>
                    atravessa o real do corpo numa eloquência silente.<br>
                    Dança (inconsciente) dança! Escancara os restos e não ditos que não<br>
                    escoam de nenhuma outra forma. Deixa o mar cobrir sua embarcação de<br>
                    todos os sentimentos naufragados pela dureza da vida.<br>
                    Dança… da menina à mulher, da cabeça ao pé, coreografada pelo indizível.<br>
                    Dança só e só dança.”</p>
                    <p>&nbsp;</p>
                    <p><strong>Por DANIELA LAUBÉ</strong></p>
                  </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Pas De Deux</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;Acabamos de encerrar o mês de junho, onde se situa temporalmente o Dia Internacional do Orgulho Gay (28/6), mês dedicado ao orgulho LGBTQIAP+, em referência à rebelião de Stonewall (NY, 1969), com atenção à emancipação e aceitação de todas as identidades e expressões de gênero. A sigla inclui lésbicas, gays, bissexuais, transexuais, transgêneros, travestis, queer, intersexos, assexuais, pansexuais, e demais orientações sexuais e identidades de gênero sob o símbolo de soma que representa uma apropriação de sexualidade com característica fluida.</p>
                      <p>Acolher começa por conhecer. Leia!</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 28px; color: #ffcc00;"><strong>ENTREVISTA COM</strong></span>&nbsp;</p>
                      <blockquote>
                      <p class="imgcenter"><img loading="lazy" style="text-align: center;" class="wp-image-14837 imgcenter" src="https://revistathebard.com/wp-content/uploads/2022/07/GABRIEL-URBANO-300x300.png" alt="" width="132" height="132" srcset="https://revistathebard.com/wp-content/uploads/2022/07/GABRIEL-URBANO-300x300.png 300w, https://revistathebard.com/wp-content/uploads/2022/07/GABRIEL-URBANO-150x150.png 150w, https://revistathebard.com/wp-content/uploads/2022/07/GABRIEL-URBANO.png 612w" sizes="(max-width: 132px) 100vw, 132px"></p>
                      <p style="text-align: center;"><strong>Gabriel Urbano</strong> – paraibano, 22 anos, artista, graduando em arquitetura, gay não binário – sobre o vogue (que ele dança lindamente) e a cultura ballroom, uma forma política e de resistência que acolhe uma diversidade de marginalizados (principalmente travestis e trans).</p>
                      </blockquote>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><strong><span style="font-size: 32px;"><span style="color: #993300;">1</span></span></strong></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Desde quando você dança e como a dança entrou na sua vida?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> Danço desde criança. Apesar de ter uma lembrança muito fraca da minha infância, lembro de festas dos colegas as quais eu ia, ou eventos que tinham música, e eu sempre era o primeiro a chegar e último a sair, porque amava passar a noite inteira dançando. Quando fui crescendo, entendi que isso não era para mim. Assim como várias outras questões relacionadas a quem eu sou, precisei negar a dança como forma de me expressar. Nem foi algo intencional, eu simplesmente entendia que não podia e ponto. Entrei em uma fase complicada de me esconder não só quanto à sexualidade, mas tudo que me fazia eu mesmo, só performava o que achava que o outros esperavam de mim. Isso durou até o fim do ensino médio, quando sai da escola, me assumi gay para a minha família e tentei reconstruir uma relação diferente comigo mesmo e com o mundo. Nessa época, comecei a acompanhar alguns dançarinos através das redes sociais e da minha irmã, que também é dançarina. Assistia a minha irmã se apresentar nos palcos e por vídeos nas redes sociais e isso despertava algo muito verdadeiro em mim. Assim, eu e a dança fomos nos reencontrando aos poucos; foi mais do que algo unilateral. Comecei, em 2018, a praticar fitdance, como uma forma de me envolver com essa energia que me chamava para a dança, e com 6 meses encontrei o Studio K17 onde fiz as minhas primeiras aulas de vários estilos como o stiletto, twerk e hiphop. Desde então tenho me permitido entrar cada vez mais nesse lugar, de onde nunca deveria ter saído, e tenho me encontrado como dançarino e como pessoa dançando a vida.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><strong><span style="font-size: 32px;"><span style="color: #993300;">2</span></span></strong></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> O que dançar proporciona de diferente para você que outras formas de arte não são capazes de proporcionar?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> Eu me considero um artista e, dentro deste rótulo, me comunico com diversas formas de expressão artística, inclusive a arquitetura, área na qual faço graduação atualmente. No entanto, a dança é algo muito especial para mim. Tendo me reprimido tanto tempo, negando para mim mesmo a oportunidade de me entender e me expressar, sempre fui muito introspectivo e retraído (e isso também é corporal; isso está na minha forma de me portar fisicamente). A dança me permite explorar esse lugar do que faço com o meu corpo no mundo e quebra uma barreira de introspecção que alimentei a minha vida inteira. Danças como o stiletto e o vogue, que são sobre autoconsciência e empoderamento, me mostraram como eu posso ser confiante e me portar desta forma, me apropriando de quem eu sou. Levo a vida com uma outra postura (de forma literal e figurativa) graças ao que a dança me permite explorar em mim mesmo e no contato artístico com os outros.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #993300;"><strong>3</strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong></span> O termo ballroom foi originalmente traduzido como “salão de baile”, mas a cultura ball, desde a década de 60, tem outro significado para o público LGBTQIAP. Como você explica esse movimento e como o conheceu?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> A cultura ballroom é e sempre foi uma cultura periférica e marginalizada. Um dos marcos para o início do movimento foi dado pela abertura da primeira House (Labeija) pela travesti preta Crystal Labeija. Ela toma essa atitude por estar cansada de não ter seus talentos valorizados nos concursos que participava por questões de transfobia e racismo. A cultura ballroom surge então em Nova York nesse contexto, sendo criada por e para pessoas pretas, principalmente trans e travestis, para ser um lugar onde os seus talentos fossem vistos e apreciados. Sendo a vivência dessas pessoas ainda hoje marginalizadas e violentadas, essa memória precisa ser evidenciada sempre que se fala de ballroom. No entanto, a ballroom não é sobre violência e sim sobre acolhimento e talento! Quando fui na minha primeira Ball, isso ficou muito nítido para mim. É um poder indescritível que atravessa os corpos dentro de uma ball. Lembro do impacto que senti quando vi a Amerikana caminhar pela primeira vez nesta ball e mais do que posso falar sobre, sendo um gay não binário e branco, é importante ouvir as vozes de pessoas como ela, uma travesti preta e um grande nome na cena de Belo Horizonte para entender o que é a ballroom. Conheci esse movimento há uns 3 ou 4 anos através das redes sociais, mas como sou paraibano, não tinha uma cena tão bem estabelecida para frequentar, então comecei a frequentar de fato ao me mudar para Belo Horizonte, em 2020.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #993300;"><strong><span style="font-size: 32px;">4</span></strong></span></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Explica para nós o que é uma House e como ela se estrutura? Você faz parte de alguma?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> As pessoas que fundaram a comunidade na década de 60, fizeram isso para lidar com o forte preconceito dirigido aos seus corpos. Esse preconceito violento também causava a expulsão de diversas dessas pessoas de suas casas e das famílias de sangue. As Houses eram formadas então seguindo uma estrutura familiar que acolhia essas pessoas e treinava para competir nas balls, valorizando seus talentos e compartilhando afeto e acolhimento dentro de uma nova família. Até hoje existe essa estrutura, mas com a evolução da sociedade e da cultura, se expandindo pelo mundo e chegando, inclusive ao Brasil, elas perderam ou ganharam significados. Existem as Houses Mainstream, que vem das pioneiras, internacionais, como a Labeija e a Ninja, por exemplo, e as Kiki Houses que são as locais, responsáveis por treinar e acolher pessoas da cena local (da cidade), expandindo essa cena cada vez mais. Não faço parte de nenhuma House Kiki ou Mainstream.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #993300;"><strong>5</strong></span></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Agora, sobre o voguing… onde você aprende e por que é tão representativo para a comunidade LGBTQUIAP+?</p>
                      <p><strong><span style="color: #993300;">GABRIEL URBANO</span></strong> Eu aprendi o vogue quando eu nasci. O vogue está em mim independente de eu estar “dançando” ou não. Em uma perfomance deste estilo, mais do que a limpeza dos passos, isso tem que estar presente: “quem é VOCÊ dentro do vogue?”. Fiz minha primeira aula, no entanto, com o Dan Oliveira, um dançarino e ilustrador paraibano com quem comecei a aprender sobre os 5 elementos do vogue femme e que estruturam sua performance: Catwalk, Duckwalk, Hads Performance, Floor Performance e Spins and Dips. Acho que cada membro da comunidade poderia dar uma resposta distinta para a importância do vogue na sua vida, mas para mim foi um lugar de encontro e exploração da minha performance de gênero. Gênero é uma performance através de signos que são assemelhados a este, e no vogue femme, criado pelas mulheres trans, a feminilidade é a chave dos movimentos. Me permitir explorar esse lado do meu corpo me trouxe e traz uma oportunidade de apropriação do meu próprio corpo de uma forma muito mais verdadeira com quem eu sou, e me sinto cada vez mais contemplado e inspirado pelo poder des dançarines que performam sua história através desses 5 elementos. Além do vogue femme existe o Old Way, que foi o primeiro estilo de Vogue inspirado em poses das revistas de moda, e o New Way. Além das categorias de dança, a ball também tem categorias de moda, beleza, lypsinc, entre outras.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #993300;"><strong>6</strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong></span> Qual a importância da Ballroom hoje? Você crê que é um ato político?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> Ainda hoje os corpos trans, travestis, pretos, não binários e LGBTQIAP são marginalizados. De acordo com o G1, a expectativa de vida de uma pessoa trans atualmente é de 35 anos e isso é muito violento. Um dos motivos para que isso ainda aconteça é a falta de acesso a esses corpos que são vistos geralmente na prostituição, enquanto os corpos cis brancos estampam capas de revista e protagonizam novelas. Por isso, é importante que esse espaço de acolhimento e valorização de talentos inegáveis que tem chegado a tantos lugares através da ballroom. Desde a música Vogue da Madonna, quando ela apresenta para o mundo inteiro essa comunidade muitas marcas e empresas já enxergam o talento da ballroom e isso só foi possível graças a essa estrutura comunitária que vem lutando para se manter e ser vista. A ballroom é puramente política, resistência, e tem muito a oferecer à sociedade. Apesar do contexto de marginalidade que a formou, a cultura é linda e só tem a acrescentar a quem tiver interesse de conhecer com respeito sua história e o que ela representa.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #993300;"><strong>7</strong></span></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Algo mais que queria nos contar? Deixe seu recado.</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> A cultura Ballroom é extremamente rica e isso tem sido cada vez mais reconhecido. Isso é muito bom e eu convido todo mundo que queria conhecer a seguir nas redes sociais os perfis de ballroom locais, nacionais e internacionais como o @mgballroom e o @ballroombr , assim como pessoas que são referências na cena que te inspirem, e procurar saber sobre a história e o significado da ballroom, por quem a para quem foi feita!</p>
                      <p><strong>Por DANIELA LAUBÉ </strong></p>
                      <video controls>
                        <source src="https://cdn.discordapp.com/attachments/1004541366904291338/1004541472080679003/video5141070643516670477.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                      </video>
                      <video controls>
                        <source src="https://cdn.discordapp.com/attachments/1004541366904291338/1004541498001469555/video5141070643516670476.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                      </video>
                      <video controls>
                        <source src="https://cdn.discordapp.com/attachments/1004541366904291338/1004541516439617597/video5141070643516670475.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                      </video>
                      <p style="text-align: center;"><a href="https://www.instagram.com/p/CcdUBeLLUA9/?igshid=YmMyMTA2M2Y%3D" role="link" target="_blank">Assistir no Instagram</a></p>
                  </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              </v-card>
              <div style="margin-bottom: 25vh;">
                <span><a class="linkGaleria" @click="goTop()">Topo</a></span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <div class="container" id="topo">
          <div>
            <a style="text-decoration: none;color: #70445E;" @click.stop="voltar()">VOLTAR</a>
          </div>
          <span cols="12" class="text-h6" style="color: #70445E;">EN DEHORS – Edição Julho/Agosto</span>
          <v-card
            style="color: #70445E;background-color: transparent;"
          >
            <v-expansion-panels multiple accordion v-model="panel">
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – DEMOCRATIZAÇÃO DA ARTE EM ESPAÇOS, CLASSES, ECONOMIAS e CORPOS – passeando entre Joinville e os espaços em branco de P. Auster</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content mt-3">
                    <p class="subtitle-1" style="color: #70445E;"><strong>DEMOCRATIZAÇÃO DA ARTE EM ESPAÇOS, CLASSES, ECONOMIAS e CORPOS – passeando entre Joinville e os espaços em branco de P. Auster</strong></p>
                    <p>&nbsp;Essa coluna é publicada na edição de julho da nossa revista, e todo mês de julho me deixa nostálgica… é o meio do ano e já foi o centro da minha vida de jovem bailarina: Festival de Dança de Joinville! É dele que estou falando, o maior e mais importante do país, acontece nessa época. Em 2022, chega a 39ª edição, todas repletas de grandes nomes, grandes trabalhos, diversas modalidades, diversidade e muita dança.</p>
                    <p>&nbsp;A grande atração de abertura este ano fica por conta do Balé do Teatro Guaíra (BTG). Lembro dos meus olhos de menina bailarina, cheios d’água, vendo “O Segundo Sopro” (1999), conhecido como “balé das águas”, a primeira coreografia no Brasil que tinha chuva no palco, inovação trazida pelo BTG. Dançar é entrega. Deixar molhar é uma fusão do corpo com a natureza que simboliza bem o que atravessa a bailarina no palco. Esse enigma, esse sentir, esse êxtase, sempre me capturou. Estar na chuva é querer molhar… dança transpira e chora naturalidades.</p>
                    <p>&nbsp;Apesar de ser linda a atmosfera artística que atrai o festival, há algo importante a ser lembrado e discutido: o quanto a arte é acessível? Do que vivem os bailarinos e como financiam suas idas a eventos importantes assim?</p>
                    <p>&nbsp;Conversei com a bailarina Helen Ribeiro, graduada com Licenciatura em Dança pela UFMG, e que está indo pela quinta vez a Joinville. Assim me contou: “A minha escola sempre fazia excursão para Joinville e, aos 14 anos, pedi para ir (como presente pelo aniversário de 15 anos). Queria estar com meu grupo e vivenciar todas as experiências possíveis. Nessa época, estava muito apaixonada e envolvida com ballet clássico. Depois disso, fui mais algumas vezes, porém sempre para fazer cursos e assistir danças. Nunca cogitei dançar nos palcos. Achava impossível ser selecionada no meio de tantas pessoas boas”.</p>
                    <p>&nbsp;Em especial desta vez, Helen teve seu vídeo aprovado nas seletivas e se apresentará nos palcos do festival com sua dança de salão. No entanto, ela fala das dificuldades para realização desse sonho: “(…) a dificuldade enquanto artista jovem e autônoma é arrecadar dinheiro que cubra custo básico da viagem, comida, hospedagem e avião. Por ser um festival grande e nas férias, tudo se torna três vezes mais caro e ainda como artista e professora não alcancei um salário que mereço e que permita viver tranquila. Principalmente, após ter ficado um tempo sem poder trabalhar pela pandemia, a classe artística foi muito afetada e ainda estou buscando espaços e oportunidades para fazer o que tanto amo: trabalhar com Dança.”</p>
                    <p>&nbsp;Helen está correndo contra o tempo para arrecadar o valor que possibilite estar presente no festival e, na próxima edição da coluna En Dehors, poderá ser lida sua entrevista completa (com relato sobre a realização desse sonho, conforme esperamos!). Até lá, os passos dessa jornada podem ser acompanhados pelo IG @bailarinahelenribeiro .</p>
                    <p>&nbsp;Nossa entrevistada não é um caso isolado. Lembro da diferença com que convivi (na pele). No mesmo grupo em que eu dançava, havia quem fosse para o festival viajando de avião (saíamos de São Paulo) e emendando uma semana de férias no Beto Carrero World. Eu ia de ônibus, com dinheiro calculado para uma semana de alimentação e – que minha mãe não leia isso (risos) – economizava na comida para trazer uma camiseta do festival para casa. O Centreventos, onde aconteciam as apresentações, era uma tentação para as bailarinas comprarem: sapatilhas, vestimentas e todos os acessórios do mundo da dança nos eram oferecidos o tempo inteiro.</p>
                    <p>&nbsp;Já ouviram alguém dizer que o ballet (e a cultura no geral) é para elite? Pois bem…</p>
                    <p>&nbsp;Por falar em realidade socioeconômica, decentralizar ações culturais é algo de importante que a turma da dança costuma fazer. E é preciso que seja feito sempre e ainda mais. Nos últimos dias 25 e 26 de junho, estive no palco do Encontro da Dança, que reuniu 40 escolas de dança da cidade de Belo Horizonte (MG) em apresentação gratuita no Shopping Boulevard. Democratização da arte.</p>
                    <p>&nbsp;Dancei, desde adolescente, nos chamados palcos livres, montados nas ruas, em praças públicas, centros comerciais, em Joinville, Ribeirão Preto, Campos do Jordão, entre outros. Arte precisa ser ofertada, compartilhada, vivida socialmente. Não se trata de nenhuma aproximação com a política do “pão e circo”, mas sim do circo ser o pão para mais que o corpo: de riso e beleza também se vive e se faz viver.</p>
                    <p>&nbsp;Existe algo na dança que prescinde de palavras. Há uma linguagem de gestos construídos na sintaxe do inconsciente que põe tanto bailarinos quanto expectadores diante do que são os significados de cada dizer do corpo para si.</p>
                    <p>&nbsp;Mencionei, na edição passada, que traria algo de bonito que vi na biografia do poeta Paul Auster: o poeta a quem a dança abalou.</p>
                    <p>&nbsp;O norte-americano teve seus poemas escritos entre 1967 e 1980 e dizia que “poesia é como tirar fotografias, enquanto prosa é como rodar um filme”.</p>
                    <p>&nbsp;Em dezembro de 1978, Auster, por acaso, assistiu a um ensaio de um espetáculo de dança coreografado pela amiga de um amigo. Algo aconteceu… A dança mexeu com o poeta a ponto de movê-lo a escrever em um tempo que pouco vinha produzindo. Ele havia praticamente cessado com sua escrita, conforme havia dito em entrevista concedida a Larry McCaffery e Sinda Gregory, em A arte da fome, no mesmo ano. Sem fotografias…</p>
                    <p>&nbsp;Da inspiração daquele momento, Auster começa a escrever o texto intitulado “Espaços em branco” que diz não ter gênero claro e ter sido uma libertação. Um breve compilado de trechos do trabalho é capaz de nos apontar o tamanho do encantamento que a dança proporciona.</p>
                    <p>&nbsp;Assim ele inicia: “Algo acontece, e a partir do momento em que começa a acontecer, nada poderá voltar a ser como era”. Era dança o que acontecia defronte a seus olhos. E algo de ininterpretável acontecia das retinas para dentro. Irreversível.</p>
                    <p>&nbsp;Ele prossegue: “Algo começa, e já não é mais o começo, mas outra coisa, que nos impele ao coração da coisa que acontece” … o peito do poeta pulsando com a batida do bailado que vê. Sobre o quanto o movimento dançado comunica, prossegue, em sua prosa de libertação: “(…) sons não são menos um gesto do que é uma mão quando se estende no ar para outra mão, e nesse gesto pode-se ler todo o alfabeto do desejo, a necessidade do corpo ser levado para além de si próprio, mesmo quando reside na esfera de seu próprio movimento”.</p>
                    <p>&nbsp;Eis um mistério universal: não há quem saia o mesmo quando se permite dançar, seja no palco, seja na cadeira de expectador. Os corpos ficam a serviço do mundo simbólico, inventando novas formas de expressão: dialeto de desejos!</p>
                    <p>&nbsp;Tal universalidade abrange toda a diversidade e a sessão Pas De Deux dessa edição traz uma conversa para lá de relevante e esclarecedora, ainda sobre a democratização da arte, mas sob outro viés.</p>
                    <p>&nbsp;</p>
                    <video controls width="346" height="432">
                      <source src="https://cdn.discordapp.com/attachments/1004541366904291338/1010202720256409710/video4900448244674331445_1.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                    </video>
                    <p style="text-align: center;"><a href="https://www.instagram.com/p/CeRnMAxAiQ7/" role="link" target="_blank">Assistir no Instagram</a></p>
                    <p style="text-align: center;">Dança menina dança.<br>
                    Tira a roupa de mulher e faz semblante de maré.<br>
                    Valsa a vontade movediça de ter pé em chão de areia:<br>
                    sempre em movimento, sempre de passagem.<br>
                    Escuta o bandolim que faz brisa nos pelos e veste a pele de música.<br>
                    Dança, menina, dança!<br>
                    Sabendo que dançar é risco que se toma com todo o corpo:<br>
                    risca o ar e escreve o gesto.<br>
                    Deixa a alma escapar pelos poros porque dançar não é performance.<br>
                    A estética mais encantadora é a da pulsão que sobressalta em dança e<br>
                    atravessa o real do corpo numa eloquência silente.<br>
                    Dança (inconsciente) dança! Escancara os restos e não ditos que não<br>
                    escoam de nenhuma outra forma. Deixa o mar cobrir sua embarcação de<br>
                    todos os sentimentos naufragados pela dureza da vida.<br>
                    Dança… da menina à mulher, da cabeça ao pé, coreografada pelo indizível.<br>
                    Dança só e só dança.”</p>
                    <p>&nbsp;</p>
                    <p><strong>Por DANIELA LAUBÉ</strong></p>
                  </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Pas De Deux</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;Acabamos de encerrar o mês de junho, onde se situa temporalmente o Dia Internacional do Orgulho Gay (28/6), mês dedicado ao orgulho LGBTQIAP+, em referência à rebelião de Stonewall (NY, 1969), com atenção à emancipação e aceitação de todas as identidades e expressões de gênero. A sigla inclui lésbicas, gays, bissexuais, transexuais, transgêneros, travestis, queer, intersexos, assexuais, pansexuais, e demais orientações sexuais e identidades de gênero sob o símbolo de soma que representa uma apropriação de sexualidade com característica fluida.</p>
                      <p>Acolher começa por conhecer. Leia!</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 28px; color: #ffcc00;"><strong>ENTREVISTA COM</strong></span>&nbsp;</p>
                      <blockquote>
                      <p class="imgcenter"><img loading="lazy" style="text-align: center;" class="wp-image-14837 imgcenter" src="https://revistathebard.com/wp-content/uploads/2022/07/GABRIEL-URBANO-300x300.png" alt="" width="132" height="132" srcset="https://revistathebard.com/wp-content/uploads/2022/07/GABRIEL-URBANO-300x300.png 300w, https://revistathebard.com/wp-content/uploads/2022/07/GABRIEL-URBANO-150x150.png 150w, https://revistathebard.com/wp-content/uploads/2022/07/GABRIEL-URBANO.png 612w" sizes="(max-width: 132px) 100vw, 132px"></p>
                      <p style="text-align: center;"><strong>Gabriel Urbano</strong> – paraibano, 22 anos, artista, graduando em arquitetura, gay não binário – sobre o vogue (que ele dança lindamente) e a cultura ballroom, uma forma política e de resistência que acolhe uma diversidade de marginalizados (principalmente travestis e trans).</p>
                      </blockquote>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><strong><span style="font-size: 32px;"><span style="color: #993300;">1</span></span></strong></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Desde quando você dança e como a dança entrou na sua vida?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> Danço desde criança. Apesar de ter uma lembrança muito fraca da minha infância, lembro de festas dos colegas as quais eu ia, ou eventos que tinham música, e eu sempre era o primeiro a chegar e último a sair, porque amava passar a noite inteira dançando. Quando fui crescendo, entendi que isso não era para mim. Assim como várias outras questões relacionadas a quem eu sou, precisei negar a dança como forma de me expressar. Nem foi algo intencional, eu simplesmente entendia que não podia e ponto. Entrei em uma fase complicada de me esconder não só quanto à sexualidade, mas tudo que me fazia eu mesmo, só performava o que achava que o outros esperavam de mim. Isso durou até o fim do ensino médio, quando sai da escola, me assumi gay para a minha família e tentei reconstruir uma relação diferente comigo mesmo e com o mundo. Nessa época, comecei a acompanhar alguns dançarinos através das redes sociais e da minha irmã, que também é dançarina. Assistia a minha irmã se apresentar nos palcos e por vídeos nas redes sociais e isso despertava algo muito verdadeiro em mim. Assim, eu e a dança fomos nos reencontrando aos poucos; foi mais do que algo unilateral. Comecei, em 2018, a praticar fitdance, como uma forma de me envolver com essa energia que me chamava para a dança, e com 6 meses encontrei o Studio K17 onde fiz as minhas primeiras aulas de vários estilos como o stiletto, twerk e hiphop. Desde então tenho me permitido entrar cada vez mais nesse lugar, de onde nunca deveria ter saído, e tenho me encontrado como dançarino e como pessoa dançando a vida.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><strong><span style="font-size: 32px;"><span style="color: #993300;">2</span></span></strong></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> O que dançar proporciona de diferente para você que outras formas de arte não são capazes de proporcionar?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> Eu me considero um artista e, dentro deste rótulo, me comunico com diversas formas de expressão artística, inclusive a arquitetura, área na qual faço graduação atualmente. No entanto, a dança é algo muito especial para mim. Tendo me reprimido tanto tempo, negando para mim mesmo a oportunidade de me entender e me expressar, sempre fui muito introspectivo e retraído (e isso também é corporal; isso está na minha forma de me portar fisicamente). A dança me permite explorar esse lugar do que faço com o meu corpo no mundo e quebra uma barreira de introspecção que alimentei a minha vida inteira. Danças como o stiletto e o vogue, que são sobre autoconsciência e empoderamento, me mostraram como eu posso ser confiante e me portar desta forma, me apropriando de quem eu sou. Levo a vida com uma outra postura (de forma literal e figurativa) graças ao que a dança me permite explorar em mim mesmo e no contato artístico com os outros.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #993300;"><strong>3</strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong></span> O termo ballroom foi originalmente traduzido como “salão de baile”, mas a cultura ball, desde a década de 60, tem outro significado para o público LGBTQIAP. Como você explica esse movimento e como o conheceu?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> A cultura ballroom é e sempre foi uma cultura periférica e marginalizada. Um dos marcos para o início do movimento foi dado pela abertura da primeira House (Labeija) pela travesti preta Crystal Labeija. Ela toma essa atitude por estar cansada de não ter seus talentos valorizados nos concursos que participava por questões de transfobia e racismo. A cultura ballroom surge então em Nova York nesse contexto, sendo criada por e para pessoas pretas, principalmente trans e travestis, para ser um lugar onde os seus talentos fossem vistos e apreciados. Sendo a vivência dessas pessoas ainda hoje marginalizadas e violentadas, essa memória precisa ser evidenciada sempre que se fala de ballroom. No entanto, a ballroom não é sobre violência e sim sobre acolhimento e talento! Quando fui na minha primeira Ball, isso ficou muito nítido para mim. É um poder indescritível que atravessa os corpos dentro de uma ball. Lembro do impacto que senti quando vi a Amerikana caminhar pela primeira vez nesta ball e mais do que posso falar sobre, sendo um gay não binário e branco, é importante ouvir as vozes de pessoas como ela, uma travesti preta e um grande nome na cena de Belo Horizonte para entender o que é a ballroom. Conheci esse movimento há uns 3 ou 4 anos através das redes sociais, mas como sou paraibano, não tinha uma cena tão bem estabelecida para frequentar, então comecei a frequentar de fato ao me mudar para Belo Horizonte, em 2020.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #993300;"><strong><span style="font-size: 32px;">4</span></strong></span></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Explica para nós o que é uma House e como ela se estrutura? Você faz parte de alguma?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> As pessoas que fundaram a comunidade na década de 60, fizeram isso para lidar com o forte preconceito dirigido aos seus corpos. Esse preconceito violento também causava a expulsão de diversas dessas pessoas de suas casas e das famílias de sangue. As Houses eram formadas então seguindo uma estrutura familiar que acolhia essas pessoas e treinava para competir nas balls, valorizando seus talentos e compartilhando afeto e acolhimento dentro de uma nova família. Até hoje existe essa estrutura, mas com a evolução da sociedade e da cultura, se expandindo pelo mundo e chegando, inclusive ao Brasil, elas perderam ou ganharam significados. Existem as Houses Mainstream, que vem das pioneiras, internacionais, como a Labeija e a Ninja, por exemplo, e as Kiki Houses que são as locais, responsáveis por treinar e acolher pessoas da cena local (da cidade), expandindo essa cena cada vez mais. Não faço parte de nenhuma House Kiki ou Mainstream.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #993300;"><strong>5</strong></span></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Agora, sobre o voguing… onde você aprende e por que é tão representativo para a comunidade LGBTQUIAP+?</p>
                      <p><strong><span style="color: #993300;">GABRIEL URBANO</span></strong> Eu aprendi o vogue quando eu nasci. O vogue está em mim independente de eu estar “dançando” ou não. Em uma perfomance deste estilo, mais do que a limpeza dos passos, isso tem que estar presente: “quem é VOCÊ dentro do vogue?”. Fiz minha primeira aula, no entanto, com o Dan Oliveira, um dançarino e ilustrador paraibano com quem comecei a aprender sobre os 5 elementos do vogue femme e que estruturam sua performance: Catwalk, Duckwalk, Hads Performance, Floor Performance e Spins and Dips. Acho que cada membro da comunidade poderia dar uma resposta distinta para a importância do vogue na sua vida, mas para mim foi um lugar de encontro e exploração da minha performance de gênero. Gênero é uma performance através de signos que são assemelhados a este, e no vogue femme, criado pelas mulheres trans, a feminilidade é a chave dos movimentos. Me permitir explorar esse lado do meu corpo me trouxe e traz uma oportunidade de apropriação do meu próprio corpo de uma forma muito mais verdadeira com quem eu sou, e me sinto cada vez mais contemplado e inspirado pelo poder des dançarines que performam sua história através desses 5 elementos. Além do vogue femme existe o Old Way, que foi o primeiro estilo de Vogue inspirado em poses das revistas de moda, e o New Way. Além das categorias de dança, a ball também tem categorias de moda, beleza, lypsinc, entre outras.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #993300;"><strong>6</strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong></span> Qual a importância da Ballroom hoje? Você crê que é um ato político?</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> Ainda hoje os corpos trans, travestis, pretos, não binários e LGBTQIAP são marginalizados. De acordo com o G1, a expectativa de vida de uma pessoa trans atualmente é de 35 anos e isso é muito violento. Um dos motivos para que isso ainda aconteça é a falta de acesso a esses corpos que são vistos geralmente na prostituição, enquanto os corpos cis brancos estampam capas de revista e protagonizam novelas. Por isso, é importante que esse espaço de acolhimento e valorização de talentos inegáveis que tem chegado a tantos lugares através da ballroom. Desde a música Vogue da Madonna, quando ela apresenta para o mundo inteiro essa comunidade muitas marcas e empresas já enxergam o talento da ballroom e isso só foi possível graças a essa estrutura comunitária que vem lutando para se manter e ser vista. A ballroom é puramente política, resistência, e tem muito a oferecer à sociedade. Apesar do contexto de marginalidade que a formou, a cultura é linda e só tem a acrescentar a quem tiver interesse de conhecer com respeito sua história e o que ela representa.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #993300;"><strong>7</strong></span></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Algo mais que queria nos contar? Deixe seu recado.</p>
                      <p><span style="color: #993300;"><strong>GABRIEL URBANO</strong></span> A cultura Ballroom é extremamente rica e isso tem sido cada vez mais reconhecido. Isso é muito bom e eu convido todo mundo que queria conhecer a seguir nas redes sociais os perfis de ballroom locais, nacionais e internacionais como o @mgballroom e o @ballroombr , assim como pessoas que são referências na cena que te inspirem, e procurar saber sobre a história e o significado da ballroom, por quem a para quem foi feita!</p>
                      <p><strong>Por DANIELA LAUBÉ </strong></p>
                      <video controls>
                        <source src="https://cdn.discordapp.com/attachments/1004541366904291338/1004541472080679003/video5141070643516670477.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                      </video>
                      <video controls>
                        <source src="https://cdn.discordapp.com/attachments/1004541366904291338/1004541498001469555/video5141070643516670476.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                      </video>
                      <video controls>
                        <source src="https://cdn.discordapp.com/attachments/1004541366904291338/1004541516439617597/video5141070643516670475.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                      </video>
                      <p style="text-align: center;"><a href="https://www.instagram.com/p/CcdUBeLLUA9/?igshid=YmMyMTA2M2Y%3D" role="link" target="_blank">Assistir no Instagram</a></p>
                  </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-card>
          <div style="margin-bottom: 25vh;">
            <span><a class="linkGaleria" @click="goTop()">Topo</a></span>
          </div>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'EdicaoJulAgo',
  components: {
    AppBar
  },
  data () {
    return {
      panel: [0],
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    voltar () {
      this.$router.back()
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
    goTop () {
      this.panel = []
    }
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
    .imgcenter {
      display: flex;
      flex-direction: row;
      align-items: center !important;
      justify-content: center !important;
    }
</style>
